import React from 'react';
import './styles.css';

const Banner = () => (
    <section id="banner" className="banner">
        <div className="banner-img">
            <img src="/assets/img/original-logo.png" alt="Logo home" />
        </div>
        <p className="one-line">Bienvenido a TotaUn, una herramienta que facilita y aumenta la interacción entre consumidores y prestadores de servicios de manera inmediata.</p>
    </section>
);

export default Banner;