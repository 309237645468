import React from "react";
import './styles.css';

const Header = () => (
    <header>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <a className="navbar-brand header-logo" href="#">
                <img src="/assets/img/white-logo.png" alt="logo" className="logo-img" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav header-links">
                    <li className="nav-item active">
                        <a className="nav-link" id="section_banner" href="#">¿Qué somos?</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="section_steps"  href="#">¿Cómo funciona?</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="section_climb" href="#">Escala tu negocio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="section_stories" href="#">Súmate</a>
                    </li>
                </ul>
            </div>
        </nav>  
    </header>
);

export default Header;