import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from './components/header'
import Banner from './components/banner'
import Steps from './components/steps'

function App() {
  return (
    <Router className="App">
      <Header></Header>
      <Banner></Banner>
      <Steps></Steps>

      <section id="climb" className="climb">
          <h1>Escala tu negocio</h1>
          <hr className="divider" />
          <p>Aumenta tus conexiones, tiempos de respuesta e ingresos con TotaUn. Ahora podrán encontrar tus servicios de manera más fácil y rápida; tu negocio crecerá. Además, si quieres comenzar un emprendiemiento y ofrecer tus servicios en el mercado, TotaUn te lo permitirá. Como prestador de servicios no tendrás que pagar nada por ser parte de la plataforma, sólo tendrás que abonar U$D 2.99 cada vez que un servicio haya sido notificado como completado tanto por ti como por el cliente.</p>
          <a href="https://info761821.typeform.com/to/zOOPns" target="_blank" className="form-link">
              <div className="btn-link">
                  <span>¡Únete a TotaUn!</span>
              </div>
          </a>
      </section>

      <section id="stories" className="stories">
          <h1>Súmate a nosotros</h1>
          <hr className="divider" />
          <div className="history">
              <div className="photo">
                  <img src="assets/img/electrician.jpg" alt="" />
              </div>
              <div className="description">
                  <p>Juan ahora podrá ofrecer sus servicios de electricista de manera más eficaz. Generará contactos cada semana; su cartera de clientes aumentará notoriamente.</p>
                  <a href="https://info761821.typeform.com/to/zOOPns" target="_blank" className="form-link">
                      <div className="btn-link">
                          <span>¿Quieres saber cómo hizo Juan?</span>
                      </div>
                  </a>
              </div>
          </div>
          <hr className="divider" />
          <div className="history row-reverse">
              <div className="photo photo-h">
                  <img src="assets/img/massage.jpg" alt="" />
              </div>
              <div className="description">
                  <p>Las reservas de Antonella irán en aumento, ahora se dará a conocer de una manera novedosa y optima. Ampliará su radio de alcance favoreciendo sus ingresos como masajista.</p>
                  <a href="https://info761821.typeform.com/to/zOOPns" target="_blank" className="form-link">
                      <div className="btn-link">
                          <span>Sigue los pasos de Antonella</span>
                      </div>
                  </a>
              </div>
              <div className="photo photo-v">
                  <img src="assets/img/massage.jpg" alt="" />
              </div>
          </div>
          <hr className="divider" />
          <div className="history">
              <div className="photo">
                  <img src="assets/img/auto-repair.jpg" alt="" />
              </div>
              <div className="description">
                  <p>Alberto llegó hace poco de Venezuela, allá tenía un taller mecánico y quiere volver a ofrecer sus servicios para emprender en su nuevo país de residencia. TotaUn le ofrecerá esa oportunidad.</p>
                  <a href="https://info761821.typeform.com/to/zOOPns" target="_blank" className="form-link">
                      <div className="btn-link">
                          <span>¿Deseas emprender como Alberto?</span>
                      </div>
                  </a>
              </div>

          </div>
      </section>
      
      <footer>
          <div className="flex">
              <div className="copyright">

                  <Link to="/" className="footer-logo">
                      <img src="assets/img/white-logo.png" alt="logo" className="logo-img" />
                  </Link>
                  <h1 className="title">
                      <span className="tota">Tota</span>
                      <span className="un">Un</span>
                  </h1>
              </div>
          </div>
          <label htmlFor="">Copyright © 2020 - Todos los Derechos Reservados</label> 
      </footer>
    </Router>
  );
}

export default App;
