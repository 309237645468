import React from "react";
import './styles.css';

const Header = () => (
    <section id="steps" className="steps-container">
        <h1>Sólo necesitas tres pasos</h1>
        <hr className="divider" />
        <div className="steps">
            <div className="step">
                <label htmlFor="first step" className="step-description">Busca tu servicio o necesidad</label>
                <img src="/assets/img/undraw_Search_1px8.svg" alt="" className="img-step" />
            </div>
            <div className="step">
                <label htmlFor="second step" className="step-description">Elije tu prestador más cercano</label>
                <img src="assets/img/undraw_people_search_wctu.svg" alt="" className="img-step" />
            </div>
            <div className="step third-step">
                <label htmlFor="third step" className="step-description">Confirma para que contacte de inmediato contigo</label>
                <img src="/assets/img/undraw_order_confirmed_aaw7.svg" alt="" className="img-step" />
            </div>
        </div>
        <div>
            <iframe className="totaun_video" src="https://www.youtube.com/embed/UXgzCSylctU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

    </section>
);

export default Header;